<script>
    import classNames from 'classnames'
    import BlockRenderer from 'blocks/BlockRenderer.svelte'
    import SeoAndSocialMeta from 'components/SeoAndSocialMeta.svelte'

    export let page
    export let theme

    const blockCount =
        (page.content && page.content.body && page.content.body.length) || 0

    var itemtype = page.content && page.content.schema_page_type
    var itemscope = !!itemtype ? true : undefined
</script>

<style>
    .Page {
        min-height: var(--layout-min-content-height);
    }</style>

<SeoAndSocialMeta {page} />

{#if page}
    <div data-storyid={page.id}/>
    <div class={classNames('Page', { [`theme-${theme}`]: theme })} {itemscope} {itemtype}>
        <slot>
            {#if page.content && page.content.body}
                {#each page.content.body as block, blockIndex}
                    <BlockRenderer {block} {blockIndex} {blockCount} />
                {/each}
            {/if}
        </slot>
    </div>
{/if}
